import React from 'react'
import Aboutus from '../pages/aboutUs/AboutUs'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
const AboutUs = () => {
  return (
    <div>
    <Navbar></Navbar>
    <Aboutus></Aboutus>
    <Footer></Footer>
    </div>

  )
}

export default AboutUs