import React from 'react';
import nainital from '../../assets/nainital-lake.jpg';
import namaste from "../../assets/namaste.jpg";
import { FaHome,  FaMailBulk, FaPhone, } from 'react-icons/fa'


import "./ContactUs.css"
const Contactus = () => {
  return (
    <div className="about-us-container">
      <div className="head-container">
        <img src={nainital} alt="About Us" className="head-image" />
        <div className="header-text">
          <h1>Contact Us</h1>
        </div>
      </div>
  
      
    
      <div className='contact-wrapper'>
      <div className="contact-image">
        <img src={namaste} alt="abc" />
      </div>
      
      <div className="contact-text">
      <h2>Get In Touch</h2>
      <div className='contact-item'>
        <FaHome className='contact-icon' size={60} />
        <p>Traveller Inn - Rangarh Road - Bhowali - Nainital - 263132 - Uttarakhand, Bhowali</p>
      </div>
      <div className='contact-item'>
        <FaPhone className='contact-icon' />
        <p>9837306487</p>
      </div>
      <div className='contact-item'>
        <FaMailBulk className='contact-icon' />
        <p>travellerinnhotel@gmail.com</p>
      </div>
    </div>
      </div>
      </div>    
  );
};

export default Contactus;
