import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Hero from '../components/hero/Hero'
import AboutSection from "../container/about/About"
import Facilities from "../container/facilities/Facilities";
import Testimonial from "../container/testimonial/Testimonial";
import Footer from '../components/footer/Footer'

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <AboutSection></AboutSection>
    <Facilities></Facilities>
  <Testimonial></Testimonial>  
      <Footer />
    </div>
  )
}

export default Home