import React from 'react';
import { FaInstagram } from "react-icons/fa6";
import { FiTarget, FiTwitter } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

import { FaLinkedin } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";

// import { faC } from '@fortawesome/free-solid-svg-icons'; 
import { FaFacebook } from "react-icons/fa";
import { Link } from 'react-router-dom';


import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container row">
                <div className="footer-col">
                    <h4>follow us</h4>
                    <div className="social-links">
                        <a href="#"><FaFacebook />
                        </a>
                        <a target='_blank' href={`https://wa.me/${process.env.REACT_APP_WHATSAPPNUMBER}`}><FaWhatsapp /></a>
                        <a href="#"><FaInstagram /></a>
                    </div>
                </div>
                <div className="footer-col">
                    <h4>company</h4>
                    <ul>
                        <li>
                            <Link to='/aboutus'>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to='/contactus'>
                                Contact Us
                            </Link>
                        </li>
                        <li>
                            <Link to='/attractions'>
                                Attractions
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>get help</h4>
                    <ul>
                        <li>Address : Rangarh Road - Bhowali - Nainital - 263132 - Uttarakhand, Bhowali</li>
                        <li>Contact Number : 9837306487</li>
                        <li>Email : travellerinnhotel@gmail.com</li>
                    </ul>
                </div>
            </div>
            <div className="map">
                <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.5468112240605!2d79.51966708116785!3d29.383555911480457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0a284e53cb639%3A0x1345572d48dde56a!2sOYO%204109%20Hotel%20Traveller%20Inn!5e0!3m2!1sen!2sin!4v1710308520783!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: "0", borderRadius: "10px", marginTop: "20px", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>

            <div className='copyright'>
                <p>
                    <FaRegCopyright />

                    The Travller Inn. Crafted by <a href='https://thezonzo.com/' style={{ color: "#7914f5", textDecoration: "underline" }}>Zonzo</a>. All Rights Reserved
                </p>
            </div>
        </footer >
    );
};

export default Footer;
