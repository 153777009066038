import React from 'react'
import Contactus from '../pages/ContactUs/Contactus'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'


const ContactUs = () => {
  return (
    <div>
    <Navbar></Navbar>
    <Contactus></Contactus>
    <Footer></Footer></div>
  )
}

export default ContactUs