import React from 'react';
import Aboutimg from "../../assests/5.jpg";
import { Link } from "react-router-dom"
import { Fade } from "react-reveal"


import './About.css'; 

const AboutSection = () => {
  return (
    <Fade bottom cascade duration={1000}>
      <div className="about-section">
        <div className="image-container">
          <img src={Aboutimg} alt="abc" />


        </div>


        <div className="text-container">
          <h2>Experience nature and her bounties like you had never before at Hotel Traveller Inn!</h2>
          <p>  Traveller Inn is located in Bhowali. Travelling from Traveller Inn is hassle-free since the property is well-connected to popular commercial and business areas of the city. The bustling city centre lies at a suitable distance from the Traveller Inn.
            The Traveller Inn convenient location makes it an ideal stay option for both leisure and business travellers.</p>
          <Link to="/aboutus"> <button>Explore more</button></Link>


        </div>
      </div>
    </Fade>
  );
}

export default AboutSection;

