import React, { useState, useEffect } from 'react'
import './Hero.css'
import HeroSectionImage1 from '../../assests/3.jpg'
import HeroSectionImage2 from '../../assests/2.jpg'
import HeroSectionImage3 from '../../assests/6.jpg'
import { Link } from 'react-router-dom'

const Hero = () => {
  const images = [HeroSectionImage1, HeroSectionImage2, HeroSectionImage3]; // Add more image URLs as needed
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); 

    return () => clearInterval(intervalId);
  }, [images.length])

  const handleChangeImage = (index) => {
    setCurrentImage(index);
  };

  return (
    <div className='hero'>
      <div className='mask'>
        <img className='heroSection-img' src={images[currentImage]} alt='Hero Section' />
      </div>
      <div className='content'>
        <h1>Welcome to Traveller Inn </h1>
        <div>
          <Link to='/contactus' className='btn btn-light'>
            Contact Us
          </Link>
        </div>
      </div>
      <div className='slider-radio-buttons'>
          {images.map((_, index) => (
            <input
              key={index}
              type='radio'
              name='image-slider'
              checked={currentImage === index}
              onChange={() => handleChangeImage(index)}
            />
          ))}
        </div>
    </div>
  )
}

export default Hero