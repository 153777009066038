import React from 'react';
import star from "../../assets/star.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import './Testimonial.css';

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  autoplaySpeed: 2000 
  };

  return (
    <div className='new'>
<h1 className='head'>What People Say?</h1>
    <Slider {...settings}>

    <div className='testimonial-container'>
        <div className="testimonial">
          <div className="icon">
          <img src={star} alt="Star Icon"/>
          </div>
          <p className="content"> "My stay at Traveller Inn was delightful. The combination of a prime location, comfortable accommodation, excellent service, and top-notch facilities made for a memorable experience. I would highly recommend this hotel to anyone visiting the area."</p>
          <p className="name">'Aditi Sharma'</p>
          </div>
          </div>
        
          <div className='testimonial-container'>
          

      <div className="testimonial">
          <div className="icon">
          <img src={star} alt="Star Icon"/>
          </div>
          <p className="content">"My stay at the Traveller Inn was fantastic! The staff was super friendly and helpful, and the room was cozy and clean. I loved the comfy bed and the delicious breakfast they served. Plus, the location was perfect for exploring the area. Definitely recommend this place for a great stay!".</p>
        
            <p className="name">'Navneet Kumar'</p>
          </div>
      </div>
      <div className='testimonial-container'>
      

      <div className="testimonial">
          <div className="icon">
          <img src={star} alt="Star Icon"/>
          </div>
          <p className="content">"Had a fantastic stay at the  Traveler Inn! The room was spacious, the bed was comfy, and the shower was great. The location was perfect for exploring the area, and the staff went above and beyond to make sure we had everything we needed. Would definitely stay here again!'</p>
            <p className="name">'Ritik'</p>
          </div>
    </div>

    <div className='testimonial-container'>
      <div className="testimonial">
          <div className="icon">
          <img src={star} alt="Star Icon"/>
          </div>
          <p className="content">"Absolutely loved my time at the  Traveler Inn! The atmosphere was so relaxed and inviting. The room was spotless, and the amenities were top-notch." </p>
            <p className="name">'Sachin Kumar Sahini'</p>
          </div>
      </div>

      <div className='testimonial-container'>

      <div className="testimonial">
          <div className="icon">
          <img src={star} alt="Star Icon"/>
          </div>
          <p className="content">"Stayed at the Traveler Inn during a road trip, and it was a highlight of our journey. The room was perfect for our family, and the kids loved the pool. The continental breakfast had a good variety of options, and the staff was incredibly helpful with local recommendations. Overall, a great experience!"</p>
            <p className="name">'Sarthak Gudwani'</p>
          </div>
      </div>
    </Slider>
    </div>

     );
    };
export default Testimonial;
