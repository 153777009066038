import { Link } from 'react-router-dom';
import './Navbar.css';
import React, { useState, useEffect } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

const Navbar = () => {

    const [click, onClick] = useState(false);
    const handleClick = () => onClick(!click);

    const [color, setcolor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setcolor(true);
        } else {
            setcolor(false);
        }
    };

    window.addEventListener('scroll', changeColor)
    // useEffect(() => {
    //     window.addEventListener('scroll', changeColor);
    //     return () => window.removeEventListener('scroll', changeColor);
    //   }, []);

    return (
        <div className={color ? "header header-bg" : "header"}>
            <Link to="/">
                <h1>Traveller Inn</h1>
            </Link>
            <ul className={click ? 'nav-menu active' :  'nav-menu'}>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/attractions">Attractions</Link>
                </li>
                <li>
                    <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                    <Link to="/contactus">Contact Us</Link>
                </li>
            </ul>
            <div className='hamburger' onClick={handleClick}>
                {click ? ( <FaTimes size={20} style={{color: "#fff"}} /> ) : (<FaBars size={20} style={{color: "#fff"}}/>)}
                
            </div>
        </div>

    )
}

export default Navbar