import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Attraction from '../components/attraction/Attraction'
// import Attraction from '../components/attraction';

const Attractions = () => {
  return (
    <div>
      <Navbar />
      <Attraction />
      <Footer />
    </div>
  )
}

export default Attractions