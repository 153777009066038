import React from 'react';
import { FaTableTennis, FaWifi, FaParking } from 'react-icons/fa';
import { GiKidSlide } from "react-icons/gi";
import { IoMdRestaurant, IoIosBonfire } from "react-icons/io";
import { GrLounge } from "react-icons/gr";
import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { IoBed } from "react-icons/io5";
import { BiSolidArea } from "react-icons/bi";
import { Fade } from "react-reveal"

import room1 from "../../assests/8.jpg";
import room2 from "../../assests/9.jpg";
import room3 from "../../assests/10.jpg";


import './Facilities.css';

const Facilities = () => {
  return (
    <div class="card">
      <Fade bottom cascade duration={1000}>
        <h1>Facilities</h1>
        <p>Take a step closer to nature by staying at Travller Inn Hotel, Nainital with spacious rooms, a bonfire,
          and other required amenities.
        </p>

        <div className="features">
          <div className="feature-card">
            <i><IoMdRestaurant></IoMdRestaurant></i>
            <h3>Restaurant</h3>
          </div>
          <div className="feature-card">
            <i><GiKidSlide></GiKidSlide></i>

            <h3>Playground</h3>
          </div>
          <div className="feature-card">
            <i><IoIosBonfire></IoIosBonfire></i>
            <h3>Bonfire</h3>
          </div>
          <div className="feature-card">
            <i><GrLounge></GrLounge></i>

            <h3>Lounge</h3>
          </div>
          <div className="feature-card">
            <i><MdOutlineSecurity />
            </i>

            <h3>24/7 Security</h3>
          </div>

          <div className="feature-card">
            <i><FaWifi></FaWifi></i>
            <h3>Free WiFi</h3>
          </div>
          <div className="feature-card">
            <i><FaUserDoctor></FaUserDoctor></i>

            <h3>Doctor on Call</h3>
          </div>
          <div className="feature-card">
            <i><FaParking></FaParking></i>
            <h3>Free Parking</h3>
          </div>
        </div>
      </Fade>

      <Fade bottom cascade duration={1000}>
        <div className='head'>
          <h1>Our Rooms</h1>
        </div>

        <div className='container'>
          <div className="room-card">
            <img src={room1} alt='' />
            <h2>Deluxe Room</h2>
            <ul>
              <li><BiSolidArea />
                225 sq.ft</li>
              <li><IoBed />
                Double Bed</li>
              <li><IoPerson></IoPerson>Max 4 Guests</li>
            </ul>

          </div>

          <div className="room-card">
            <img src={room2} alt="" />

            <h2>Super Deluxe Room</h2>
            <ul>
              <li><BiSolidArea />
                144 sq.ft</li>
              <li><IoBed />
                Double Bed</li>
              <li><IoPerson></IoPerson>   Max 4 Guests</li>
            </ul>

          </div>
          <div className="room-card">
            <img src={room3} alt='' />
            <h2>Triple Room</h2>
            <ul>
              <li><BiSolidArea />
                144 sq.ft</li>
              <li><IoBed />
                Double Bed</li>
              <li><IoPerson></IoPerson>   Max 5 Guests</li>
            </ul>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Facilities;
