// AboutUs.js
import React from 'react';
import Bhimtal from '../../assets/lake.jpg';
import Aboutimg from "../../assests/6.jpg";

import "./AboutUs.css"
const Aboutus = () => {
  return (
    <div className="about-us-container">
      <div className="head-container">
        <img src={Bhimtal} alt="About Us" className="head-image" />
        <div className="header-text">
          <h1>About Us</h1>
        </div>
      </div>

      <div className='about-wrapper'>
        <div className="image-container">
          <img src={Aboutimg} alt="abc" />
        </div>

        <div className="about-text">
          <h2>Who are we?</h2>
          <p>
            Nestled in the serene landscapes, Hotel Traveller Inn Bhovali is a charming three-star retreat, just 29.6 kms from Kathgodam Railway Station and 11.8 kms from Roadways Bus Stand, standing proudly at 1706 meters above sea level.
          </p>

          <p>
            Catering to both business and leisure travelers, the hotel features a delightful multi-cuisine restaurant offering mouthwatering Indian and international dishes. With a conference hall accommodating 70-80 guests, a cozy coffee shop, and spacious party lawns, it's an ideal destination for any occasion.
          </p>

          <p>
            Whether it's a corporate affair or a social celebration, the hotel offers versatile options with its expansive party lawns, providing a picturesque backdrop for any event.
            .</p>

        </div>

      </div>

      <div className="core-values-container">
        <h1>Our Core Values</h1>
        <div className="core-value">
          <h2>Hospitality</h2>
          <p>
            Providing exceptional service and creating a welcoming atmosphere for guests is often considered the cornerstone of a hotel's core values.
          </p>
        </div>
        <div className="core-value">
          <h2>Customer Satisfaction</h2>
          <p>
            Ensuring that guests have a memorable and enjoyable experience is crucial. This includes meeting their needs promptly, addressing any concerns or issues, and striving to exceed their expectations.
          </p>
        </div>
        <div className="core-value">
          <h2>Quality</h2>
          <p>
            Maintaining high standards of cleanliness, comfort, and amenities is essential for building trust and loyalty among guests.
          </p>
        </div>
        <div className="core-value">
          <h2>Integrity</h2>
          <p>
            Acting with honesty, transparency, and ethical conduct in all business dealings, including pricing, advertising, and guest interactions, is vital for establishing credibility and trust.
          </p>
        </div>
        <div className="core-value">
          <h2>Continuous Improvement</h2>
          <p>
            Embracing innovation, soliciting feedback from guests and employees, and continually seeking ways to enhance the guest experience and operational efficiency are essential for staying competitive and adapting to changing trends.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
